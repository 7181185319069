import { ReviewsInterface } from '../../interfaces/ReviewsInterface'
import axios from '../../utils/axios'
import { CreateProductReviewPayload } from './product.types'

export function readProduct(id: number) {
  return axios({
    method: 'GET',
    url: `/product/${id}.json`,
  })
}

export function readCrossProducts(id: number) {
  return axios({
    method: 'GET',
    url: `/product/${id}/cross-sell.json`,
  })
}

export function readUpSellProducts(id: number) {
  return axios({
    method: 'GET',
    url: `/product/${id}/up-sell.json`,
  })
}

export function readTopSellProducts() {
  return axios({
    method: 'GET',
    url: '/product/topsell.json',
  })
}

export function readProductsToReview(token: string) {
  return axios<any, { data: ReviewsInterface }>({
    method: 'GET',
    url: `/reviews/token_available_items/${token}.json`,
  })
}

export function createProductReview(payload: CreateProductReviewPayload) {
  return axios({
    method: 'POST',
    url: '/reviews.json',
    data: payload,
  })
}
