import { FormikHelpers } from 'formik'
import React, { ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import routes from '../../app/routes'
import ClubContext from '../../context/ClubContext'
import HistoryContext from '../../context/HistoryContext'
import useConfirmationDialog from '../../hooks/app/useConfirmationDialog'
import useRefetchOnWindowFocus from '../../hooks/app/useRefetchOnWindowFocus'
import { useAuth } from '../../hooks/auth/auth'
import useCart from '../../hooks/cart/cart'
import ChannelInterface, { FlowType } from '../../interfaces/ChannelInterface'
import ClubInterface from '../../interfaces/ClubInterface'
import { readAppStateRequest } from '../../services/app/app.actions'
import * as api from '../../services/club/club.api'
import { handleErrorResponse } from '../../utils/formErrorHelper'
import { getPreferredLocale } from '../../utils/locale'
import ClubSignIn from '../../web/views/ClubSignIn/ClubSignIn'
import { ClubSignInFormValues } from '../ClubSignInForm/ClubSignInForm'
import ClubCacheManager from './ClubCacheManager'

const PASSWORD_KEY = 'password'

interface ClubProviderProps {
  channel: ChannelInterface | null
  children: ReactNode
}

const ClubProvider = (props: ClubProviderProps) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const history = useContext(HistoryContext)
  const location = useLocation()
  const [club, setClub] = useState<ClubInterface | null>(null)
  const [isInClubFlow, setIsInClubFlow] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [shouldDisplayLoginForm, setShouldDisplayLoginForm] = useState(false)
  const [loginError, setLoginError] = useState<string | null>(null)
  const confirmationDialog = useConfirmationDialog()
  const cart = useCart()
  const auth = useAuth()
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const handleSignInSubmit = async (values: ClubSignInFormValues, formik: FormikHelpers<ClubSignInFormValues>) => {
    formik.setSubmitting(true)

    try {
      await authenticate(values.password)
    } finally {
      formik.setSubmitting(false)
    }
  }

  const isExpired = (endAt: string | number | undefined): boolean => {
    const now = Date.now()
    const endAtTimestamp = parseInt(`${endAt || 0}`, 10) * 1000
    return now >= endAtTimestamp
  }

  const authenticate = async (password: string, currentClub?: ClubInterface | null) => {
    try {
      setIsAuthenticated(false)
      setShouldDisplayLoginForm(false)
      setIsLoading(true)
      setLoginError(null)

      const result = await api.auth(password)

      if (currentClub?.id && result.data.id !== currentClub.id && cart.cart.items.length > 0) {
        confirmationDialog.confirm({
          title: intl.formatMessage({ id: 'Club.SwitchAccount.ConfirmationDialog.Heading', defaultMessage: 'Switch Account Confirmation' }),
          content: intl.formatMessage({
            id: 'Club.SwitchAccount.ConfirmationDialog.Content',
            defaultMessage: 'You are about to switch to a different account. This will clear your current cart. Do you wish to proceed?',
          }),
          cancelLabel: intl.formatMessage({ id: 'Club.SwitchAccount.ConfirmationDialog.Cancel', defaultMessage: 'Cancel' }),
          confirmLabel: intl.formatMessage({ id: 'Club.SwitchAccount.ConfirmationDialog.Confirm', defaultMessage: 'Confirm' }),
          onSubmit: () => {
            cart.clearCart()
            signIn(result.data)
          },
          onClose: () => {
            signIn(currentClub)
          },
        })
      } else {
        signIn(result.data)
      }
    } catch (error: any) {
      setShouldDisplayLoginForm(true)

      const errorKeys = Object.keys(error?.response?.data?.fields || {})
      const errorMessages = errorKeys.length === 1 ? error.response.data.fields[errorKeys[0]] : []
      const errorMessage = errorMessages.length === 1 ? (errorMessages[0] ?? '') : ''

      if (errorMessage?.length! > 0) {
        setLoginError(errorMessage)
      } else {
        handleErrorResponse(
          error.response,
          {
            setFieldError: (field, message) => {
              if (field && message) {
                setLoginError(message)
              }
            },
          },
          true,
        )
      }
    } finally {
      setIsLoading(false)
    }
  }

  const signIn = (inClub: ClubInterface | null | undefined) => {
    const isAuthenticate = Boolean(inClub)

    setClub(inClub ?? null)
    setIsAuthenticated(isAuthenticate)

    if (isAuthenticate) {
      dispatch(readAppStateRequest(getPreferredLocale()))
      history.push(routes.home)
    }
  }

  const logout = async () => {
    setIsLoading(true)

    try {
      await api.auth('')

      cart.clearCart()
      setClub(null)
      setIsAuthenticated(false)
      setLoginError(null)
      ClubCacheManager.remove()
      setShouldDisplayLoginForm(true)

      auth.logOut(
        () => {
          setIsLoading(false)
          history.push(routes.home)
        },
        err => {
          setLoginError(err.message)
          setIsLoading(false)
          history.push(routes.home)
        },
      )
    } catch (e) {
      setIsLoading(false)
      console.error(e)
    }
  }

  const logoutIfExpired = async (inClub: ClubInterface | null | undefined) => {
    if (inClub && isExpired(inClub.endAt)) {
      await logout()
    }
  }

  const refreshAndLogoutIfExpired = useCallback(async () => {
    if (club) {
      const newClub = await refreshClubData(club)
      logoutIfExpired(newClub)
    }
  }, [club])

  const refreshClubData = async (inClub: ClubInterface | null | undefined) => {
    if (inClub) {
      const newClubData = await api.getClubData().catch(() => null)

      if (!newClubData?.data) {
        ClubCacheManager.remove()
        setShouldDisplayLoginForm(true)
        return null
      }

      setClub(newClubData.data)
      setIsAuthenticated(true)

      return newClubData.data
    }

    return null
  }

  useEffect(() => {
    if (props.channel?.flowType !== FlowType.Club) {
      ClubCacheManager.remove()
      setShouldDisplayLoginForm(false)
      setClub(null)
      setIsInClubFlow(false)
      setIsAuthenticated(false)
      setIsLoading(false)
      setLoginError(null)
      return
    }

    setIsInClubFlow(true)

    const queryParams = new URLSearchParams(location.search)
    const passwordFromURL = queryParams.get(PASSWORD_KEY)
    const cachedData = ClubCacheManager.load()

    if (passwordFromURL) {
      authenticate(passwordFromURL, cachedData?.club)
    } else if (cachedData.club && cachedData.isAuthenticated) {
      refreshClubData(cachedData.club)
    } else {
      ClubCacheManager.remove()
      setShouldDisplayLoginForm(true)
    }
  }, [location.search, props.channel?.flowType])

  useEffect(() => {
    if (club && isAuthenticated) {
      ClubCacheManager.save({
        club,
        isAuthenticated,
      })
    }
  }, [club, isAuthenticated])

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    if (club) {
      if (isExpired(club.endAt)) {
        logout()
      } else {
        const now = Date.now()
        const endAtTimestamp = parseInt(`${club.endAt}`, 10) * 1000
        const timeUntilLogout = endAtTimestamp - now

        timeoutRef.current = setTimeout(() => {
          logout()
        }, timeUntilLogout)
      }
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [club?.endAt])

  useRefetchOnWindowFocus(refreshAndLogoutIfExpired)

  return (
    <ClubContext.Provider
      value={{
        club,
        isInClubFlow,
        isAuthenticated,
        isLoading,
        shouldDisplayLoginForm,
        loginError,
        authenticate,
        logout,
      }}
    >
      {isLoading || shouldDisplayLoginForm || loginError ? <ClubSignIn onSubmit={handleSignInSubmit} /> : props.children}
    </ClubContext.Provider>
  )
}

export default ClubProvider
