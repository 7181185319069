import { useEffect } from 'react'

type RefetchFunc = () => Promise<void> | void

const useRefetchOnWindowFocus = (refetch: RefetchFunc) => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refetch()
      }
    }

    const handleFocus = () => {
      refetch()
    }

    window.addEventListener('focus', handleFocus)
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      window.removeEventListener('focus', handleFocus)
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [refetch])
}

export default useRefetchOnWindowFocus
